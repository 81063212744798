import rate from "./edlDataStructureModuleEntryRate";
import _ from "lodash";

const walls = {
  'name': 'Murs',
  'defaultRates': [rate.condition, rate.cleaning, rate.typeWall1],
  'autoFillObservation': "wallCeiling",
  'content': [
    {
      'name': 'Accès',
    },
    {
      'name': 'Gauche',
    },
    {
      'name': 'Face',
    },
    {
      'name': 'Droite',
    }
  ],
  'orderAlphabetically': false
};

const floor = {
  'name': 'Sols',
  'defaultRates': [rate.condition, rate.cleaning, rate.typeFloor1],
  'autoFillObservation': "floor",
};

const ceiling = {
  'name': 'Plafond',
  'defaultRates': [rate.condition, rate.cleaning, rate.typeCeiling1],
  'autoFillObservation': "wallCeiling",
};

const plinths = {
  'name': 'Plinthes',
  'defaultRates': [rate.condition, rate.cleaning, rate.typePlinths],
  'autoFillObservation': "plinth",
};

const door = {
  'name': 'Porte',
  'autoFillObservation': "door",
  'defaultRates': [rate.condition, rate.cleaning, rate.state],
  'content': [
    {
      'name': 'Intérieur',
      'defaultRates': [rate.condition, rate.cleaning, rate.state, rate.typeDoor1],
    },
    {
      'name': 'Extérieur',
      'defaultRates': [rate.condition, rate.cleaning, rate.state, rate.typeDoor1],
    },
    {
      'name': 'Serrure/Verrou',
      'autoFillObservation': 'lockHandle',
      'defaultRates': [rate.condition, rate.cleaning, rate.state],
    },
    {
      'name': 'Poignée',
      'autoFillObservation': 'lockHandle',
      'defaultRates': [rate.condition, rate.cleaning, rate.state, rate.typeDoor1],
    },
    {
      'name': 'Butée',
      'defaultRates': [rate.condition, rate.cleaning, rate.state],
    },
    {
      'name': 'Judas',
      'defaultRates': [rate.condition, rate.cleaning, rate.state],
    },
    {
      'name': 'Barre de seuil',
      'defaultRates': [rate.condition, rate.cleaning, rate.typeDoor1],
    }
  ],
  'orderAlphabetically': false
};

const windows = {
  'name': 'Fenêtres',
  'autoFillObservation': "window",
  'defaultRates': [rate.condition, rate.cleaning, rate.state],
  'content': [
    {
      'name': 'Vitre',
      'defaultRates': [rate.condition, rate.cleaning, rate.typeWindowPane]
    },
    {
      'name': 'Encadrement',
      'defaultRates': [rate.condition, rate.cleaning, rate.state, rate.typeWindowFrame]
    },
    {
      'name': 'Volet',
      'autoFillObservation': 'shutter',
      'defaultRates': [rate.condition, rate.cleaning, rate.state, rate.typeWindowShutters]
    }
  ],
  'orderAlphabetically': false
};

const storage = {
  'name': 'Rangements/Placards',
  'defaultRates': [rate.condition, rate.cleaning, rate.state, rate.typeWall1, rate.typeFloor1, rate.typeCeiling1, rate.typeDoor1, rate.typePlinths],
  'autoFillObservation': "storage",
  'content': [
    {
      'name': 'Murs',
      'autoFillObservation': "wallCeiling",
    },
    {
      'name': 'Sol',
      'autoFillObservation': "floor",
    },
    {
      'name': 'Plinthe(s)',
      'autoFillObservation': "plinth",
    },
    {
      'name': 'Plafond',
    },
    {
      'name': 'Porte(s)',
      'autoFillObservation': "door",
    },
    {
      'name': 'Equipement(s)',
    }
  ],
  'orderAlphabetically': false
};

const mezzanine = {
  'name': 'Mezzanine',
  'defaultRates': [rate.condition, rate.cleaning, rate.state],
  'content': [
    {
      'name': 'Mur',
      'autoFillObservation': "wallCeiling",
      'defaultRates': [rate.condition, rate.cleaning, rate.state, rate.typeWall1],
    },
    {
      'name': 'Sol',
      'autoFillObservation': "floor",
      'defaultRates': [rate.condition, rate.cleaning, rate.state, rate.typeFloor1],
    },
    {
      'name': 'Plafond',
      'defaultRates': [rate.condition, rate.cleaning, rate.state, rate.typeCeiling1],
    },
    {
      'name': 'Escalier',
      'defaultRates': [rate.condition, rate.cleaning],
    },
    {
      'name': 'Garde-corps',
      'defaultRates': [rate.condition, rate.cleaning, rate.state],
    },
    {
      'name': 'Rangements',
      'defaultRates': [rate.condition, rate.cleaning, rate.state]
    }
  ],
  'orderAlphabetically': false
};

const steps = {
  'name': 'Marches',
  'defaultRates': [rate.condition, rate.cleaning, rate.state, rate.typeFloor2]
};

const railing = {
  'name': 'Rambarde',
  'defaultRates': [rate.condition, rate.cleaning, rate.state, rate.typeDoor2]
};

const radiators = {
  'name': 'Radiateurs',
  'defaultRates': [rate.condition, rate.cleaning, rate.state, rate.number, rate.typeRadiator],
  'autoFillObservation': "radiator",
};

const ventilation = {
  'name': 'Ventilation',
  'defaultRates': [rate.condition, rate.cleaning, rate.state, rate.typeVentilation],
  'autoFillObservation': "vent",
};

const electricSystemSimple = {
  'name': 'Installations Electriques (simple)',
  'defaultRates': [rate.condition, rate.cleaning, rate.state, rate.number],
  'content': [
    {
      'name': 'Prise(s)',
    },
    {
      'name': 'Interrupteur(s)',
    },
    {
      'name': 'Plafonnier(s)',
    },
    {
      'name': 'Applique(s)',
    },
    {
      'name': 'Ampoule+douille',
    }
  ],
  'orderAlphabetically': false
};

const electricSystemComplex = {
  'name': 'Installations Electriques (complet)',
  'defaultRates': [rate.condition, rate.cleaning, rate.state, rate.number],
  'content': [
    {
      'name': 'Prise(s)',
    },
    {
      'name': 'Prise(s) TV',
    },
    {
      'name': 'Prise(s) téléphone',
    },
    {
      'name': 'Prise(s) éthernet',
    },
    {
      'name': 'Interrupteur(s)',
    },
    {
      'name': 'Plafonnier(s)',
    },
    {
      'name': 'Applique(s)',
    },
    {
      'name': 'Ampoule+douille',
    }
  ],
  'orderAlphabetically': false
};

const tiles = {
  'name': 'Faïence',
  'defaultRates': [rate.condition, rate.cleaning]
};

const sinkComplex = {
  'name': 'Complexe Évier/Vasque/Lavabo',
  'defaultRates': [rate.condition, rate.cleaning, rate.state],
  'content': [
    {
      'name': 'Évier/Vasque/Lavabo',
      'autoFillObservation': "sink",
      'defaultRates': [rate.condition, rate.cleaning, rate.typeSink],
    },
    {
      'name': 'Bonde',
      'defaultRates': [rate.condition, rate.cleaning, rate.state, rate.typeDrain],
    },
    {
      'name': 'Robinet',
      'autoFillObservation': "faucet",
      'defaultRates': [rate.condition, rate.cleaning, rate.state, rate.typeFaucet],
    },
    {
      'name': 'Credence',
      'autoFillObservation': "splashback",
      'defaultRates': [rate.condition, rate.cleaning, rate.typeKitchenSplashback],
    },
    {
      'name': 'Siphon',
      'autoFillObservation': "siphon",
      'defaultRates': [rate.condition, rate.cleaning, rate.state, rate.typeSiphon],
    },
    {
      'name': 'Joint étanchéité',
      'autoFillObservation': "seal",
      'defaultRates': [rate.condition, rate.cleaning, rate.state, rate.typeSeal],
    },
    {
      'name': 'Elément haut',
      'defaultRates': [rate.condition, rate.cleaning, rate.state,rate.typeKitchenUpperElement],
    },
    {
      'name': 'Meuble sous évier',
      'autoFillObservation': "sinkCabinet",
      'defaultRates': [rate.condition, rate.cleaning, rate.state, rate.typeSinkCabinet],
    }
  ],
  'orderAlphabetically': false
};

const bathroomSinkComplex = {
  'name': 'Complexe Évier/Vasque/Lavabo',
  'defaultRates': [rate.condition, rate.cleaning, rate.state],
  'content': [
    {
      'name': 'Évier/Vasque/Lavabo',
      'autoFillObservation': "sink",
      'defaultRates': [rate.condition, rate.cleaning, rate.typeBathroomSink],
    },
    {
      'name': 'Bonde',
      'defaultRates': [rate.condition, rate.cleaning, rate.state, rate.typeDrain],
    },
    {
      'name': 'Robinet',
      'autoFillObservation': "faucet",
      'defaultRates': [rate.condition, rate.cleaning, rate.state, rate.typeFaucet],
    },
    {
      'name': 'Credence',
      'autoFillObservation': "splashback",
      'defaultRates': [rate.condition, rate.cleaning, rate.typeKitchenSplashback],
    },
    {
      'name': 'Siphon',
      'autoFillObservation': "siphon",
      'defaultRates': [rate.condition, rate.cleaning, rate.state, rate.typeSiphon],
    },
    {
      'name': 'Joint étanchéité',
      'autoFillObservation': "seal",
      'defaultRates': [rate.condition, rate.cleaning, rate.state, rate.typeSeal],
    },
    {
      'name': 'Elément haut',
      'defaultRates': [rate.condition, rate.cleaning, rate.state,rate.typeKitchenUpperElement],
    },
    {
      'name': 'Meuble sous évier',
      'autoFillObservation': "sinkCabinet",
      'defaultRates': [rate.condition, rate.cleaning, rate.state, rate.typeSinkCabinet],
    }
  ],
  'orderAlphabetically': false
};

const toiletComplex = {
  'name': 'Complexe WC',
  'defaultRates': [rate.condition, rate.cleaning, rate.state],
  'content': [
    {
      'name': 'Cuve/Réservoir',
      'autoFillObservation': "tank",
      'defaultRates': [rate.condition, rate.cleaning, rate.typeToiletTank],
    },
    {
      'name': 'Chasse d eau',
      'autoFillObservation': "flush",
      'defaultRates': [rate.condition, rate.cleaning, rate.state],
    },
    {
      'name': 'Abattant',
      'defaultRates': [rate.condition, rate.cleaning, rate.state, rate.typeToiletLid],
    }
  ],
  'orderAlphabetically': false
};

const equippedKitchen = {
  'name': 'Cuisine équipée',
  'defaultRates': [rate.condition, rate.cleaning, rate.state],
  'content': [
    {
      'name': 'Meubles',
    },
    {
      'name': 'Four',
      'autoFillObservation': "oven",
    },
    {
      'name': 'Micro-onde',
      'autoFillObservation': "oven",
    },
    {
      'name': 'Plaques de cuisson',
      'autoFillObservation': "cookingPlate",
    },
    {
      'name': 'Réfrigérateur',
    },
    {
      'name': 'Hotte',
      'autoFillObservation': "hood",
    },
    {
      'name': 'Lave-vaisselle',
    },
    {
      'name': 'Lave-linge',
    }
  ],
  'orderAlphabetically': false
};

const handWash = {
  'name': 'Lave mains',
  'defaultRates': [rate.condition, rate.cleaning, rate.state],
  'content': [
    {
      'name': 'Bac',
      'defaultRates': [rate.condition, rate.cleaning, rate.typeSink],
    },
    {
      'name': 'Bonde',
      'defaultRates': [rate.condition, rate.cleaning, rate.state, rate.typeDrain],
    },
    {
      'name': 'Robinet',
      'autoFillObservation': "faucet",
      'defaultRates': [rate.condition, rate.cleaning, rate.state, rate.typeFaucet],
    },
    {
      'name': 'Siphon',
      'autoFillObservation': "siphon",
      'defaultRates': [rate.condition, rate.cleaning, rate.state, rate.typeSiphon],
    },
    {
      'name': 'Joint étanchéité',
      'autoFillObservation': "seal",
      'defaultRates': [rate.condition, rate.cleaning, rate.state, rate.typeSeal],
    },
    {
      'name': 'Meuble sous évier',
      'autoFillObservation': "sinkCabinet",
      'defaultRates': [rate.condition, rate.cleaning, rate.state, rate.typeSinkCabinet],
    }
  ],
  'orderAlphabetically': false
};

const bathtub = {
  'name': 'Baignoire',
  'defaultRates': [rate.condition, rate.cleaning, rate.state],
  'autoFillObservation': "bathtub",
  'content': [
    {
      'name': 'Vasque',
      'defaultRates': [rate.condition, rate.cleaning, rate.typeSink],
    },
    {
      'name': 'Bonde',
      'defaultRates': [rate.condition, rate.cleaning, rate.state, rate.typeDrain],
    },
    {
      'name': 'Robinet',
      'autoFillObservation': "faucet",
      'defaultRates': [rate.condition, rate.cleaning, rate.state, rate.typeFaucet],
    },
    {
      'name': 'Siphon',
      'autoFillObservation': "siphon",
      'defaultRates': [rate.condition, rate.cleaning, rate.state, rate.typeSiphon],
    },
    {
      'name': 'Joint étanchéité',
      'autoFillObservation': "seal",
      'defaultRates': [rate.condition, rate.cleaning, rate.state, rate.typeSeal],
    },
    {
      'name': 'Ecran',
      'defaultRates': [rate.condition, rate.cleaning, rate.state],
    },
    {
      'name': 'Tablier de baignoire',
      'autoFillObservation': "bathPannel",
      'defaultRates': [rate.condition, rate.cleaning, rate.state, rate.typeBathPannel],
    }
  ],
  'orderAlphabetically': false
};

const shower = {
  'name': 'Douche',
  'autoFillObservation': "bathtub",
  'defaultRates': [rate.condition, rate.cleaning, rate.state],
  'content': [
    {
      'name': 'Vasque',
      'defaultRates': [rate.condition, rate.cleaning, rate.typeSink],
    },
    {
      'name': 'Bonde',
      'defaultRates': [rate.condition, rate.cleaning, rate.state, rate.typeDrain],
    },
    {
      'name': 'Robinet',
      'autoFillObservation': "faucet",
      'defaultRates': [rate.condition, rate.cleaning, rate.state, rate.typeFaucet],
    },
    {
      'name': 'Siphon',
      'autoFillObservation': "siphon",
      'defaultRates': [rate.condition, rate.cleaning, rate.state, rate.typeSiphon],
    },
    {
      'name': 'Joint étanchéité',
      'autoFillObservation': "seal",
      'defaultRates': [rate.condition, rate.cleaning, rate.state, rate.typeSeal],
    },
    {
      'name': 'Ecran',
      'defaultRates': [rate.condition, rate.cleaning, rate.state],
    }
  ],
  'orderAlphabetically': false
};

const fence = {
  'name': 'Clôture/Haie',
  'defaultRates': [rate.condition, rate.cleaning, rate.state]
};

const lawn = {
  'name': 'Pelouse/Dalle',
  'defaultRates': [rate.condition, rate.cleaning, rate.state]
};

const vegetation = {
  'name': 'Arbres/Végétation',
  'defaultRates': [rate.condition, rate.cleaning, rate.state]
};

const smokeDetector = {
  'name': 'Détecteur de fumée',
  'defaultRates': [rate.condition, rate.state]
};

const letterbox = {
  'name': 'Boîte aux lettres',
  'defaultRates': [rate.condition, rate.state]
};

const letterboxLock = {
  'name': 'Serrure boîte aux lettres',
  'defaultRates': [rate.condition, rate.state]
};

const intercom = {
  'name': 'Interphone/Visiophone',
  'defaultRates': [rate.condition, rate.state]
};

const doorbell = {
  'name': 'Sonnette',
  'defaultRates': [rate.condition, rate.state]
};

const hotwaterHeating = {
  'name': 'Production eau chaude',
  'defaultRates': [rate.condition, rate.state, rate.hotwaterEquipment],
  'whiteListedModules': 'Energie', // Afficher cette entrée uniquement pour les modules suivants
};

const heating = {
  'name': 'Chauffage',
  'defaultRates': [rate.condition, rate.state, rate.heatingEquipment],
  'whiteListedModules': 'Energie', // Afficher cette entrée uniquement pour les modules suivants
};

const electricMeter = {
  'name': 'Compteur électrique',
  'defaultRates': [rate.state, rate.typeElectricMeterReading, rate.serialNumber, rate.kwPeak, rate.kwOffPeak],
  'whiteListedModules': 'Energie', // Afficher cette entrée uniquement pour les modules suivants
};

const hotwaterMeter = {
  'name': 'Compteur eau et/ou gaz',
  'defaultRates': [rate.state, rate.typeHotwaterMeterReading, rate.serialNumber, rate.m3],
  'whiteListedModules': 'Energie', // Afficher cette entrée uniquement pour les modules suivants
};

const keys = {
  'name': 'Remise des clés',
  'defaultRates': [rate.number, rate.state],
  'whiteListedModules': 'Autre', // Afficher cette entrée uniquement pour les modules sélectionnés
  'content': [
    {
      'name': 'Porte palière',
      'defaultRates': [rate.number],
    },
    {
      'name': 'Porte immeuble',
      'defaultRates': [rate.number],
    },
    {
      'name': 'Vigik',
      'defaultRates': [rate.number, rate.state]
    },
    {
      'name': 'Boîte aux lettres',
      'defaultRates': [rate.number],
    },
    {
      'name': 'Cave',
      'defaultRates': [rate.number],
    },
    {
      'name': 'Local commun',
      'defaultRates': [rate.number],
    },
    {
      'name': 'Garage',
      'defaultRates': [rate.number],
    },
    {
      'name': 'Bip/Télécommande',
      'defaultRates': [rate.number, rate.state]
    },
    {
      'name': 'Non identifiée',
      'defaultRates': [rate.number],
    },
    {
      'name': 'Total des clés',
      'defaultRates': [rate.number],
    }
  ],
  'orderAlphabetically': false
};

const fuseboard = {
  'name': 'Tableau de fusibles/Disjoncteurs',
  'defaultRates': [rate.condition, rate.state],
  'cantBeAdded': true,
};

const kitAlurT1 = {
  'name': 'Kit Alur T1',
  'defaultRates': [rate.number, rate.condition],
  'content': [
    {
      'name': 'Fourchettes',
      'rate': {'number': 2}
    },
    {
      'name': 'Cuillières à café',
      'rate': {'number': 2}
    },
    {
      'name': 'Cuillières à soupe',
      'rate': {'number': 2}
    },
    {
      'name': 'Couteaux',
      'rate': {'number': 2}
    },
    {
      'name': 'Couteaux office',
      'rate': {'number': 1}
    },
    {
      'name': 'Verres',
      'rate': {'number': 2}
    },
    {
      'name': 'Mugs',
      'rate': {'number': 2}
    },
    {
      'name': 'Assiettes',
      'rate': {'number': 2}
    },
    {
      'name': 'Passoire',
      'rate': {'number': 1}
    },
    {
      'name': 'Casserole',
      'rate': {'number': 1}
    },
    {
      'name': 'Poêle',
      'rate': {'number': 1}
    },
    {
      'name': 'Louche',
      'rate': {'number': 1}
    },
    {
      'name': 'Cuillière en bois',
      'rate': {'number': 1}
    },
    {
      'name': 'Balai',
      'rate': {'number': 1}
    },
    {
      'name': 'Balai brosse',
      'rate': {'number': 1}
    },
    {
      'name': 'Pelle/balayette',
      'rate': {'number': 1}
    },
    {
      'name': 'Seau',
      'rate': {'number': 1}
    },
    {
      'name': 'Couverture polaire',
      'rate': {'number': 1}
    }
  ],
  'orderAlphabetically': true
};

const kitAlurT2 = {
  'name': 'Kit Alur T2',
  'defaultRates': [rate.number, rate.condition],
  'content': [
    {
      'name': 'Fourchettes',
      'rate': {'number': 3}
    },
    {
      'name': 'Cuillières à café',
      'rate': {'number': 3}
    },
    {
      'name': 'Cuillières à soupe',
      'rate': {'number': 3}
    },
    {
      'name': 'Couteaux',
      'rate': {'number': 3}
    },
    {
      'name': 'Couteaux office',
      'rate': {'number': 1}
    },
    {
      'name': 'Verres',
      'rate': {'number': 3}
    },
    {
      'name': 'Mugs',
      'rate': {'number': 3}
    },
    {
      'name': 'Assiettes',
      'rate': {'number': 3}
    },
    {
      'name': 'Passoire',
      'rate': {'number': 1}
    },
    {
      'name': 'Casserole',
      'rate': {'number': 1}
    },
    {
      'name': 'Poêle',
      'rate': {'number': 1}
    },
    {
      'name': 'Louche',
      'rate': {'number': 1}
    },
    {
      'name': 'Cuillière en bois',
      'rate': {'number': 1}
    },
    {
      'name': 'Balai',
      'rate': {'number': 1}
    },
    {
      'name': 'Balai brosse',
      'rate': {'number': 1}
    },
    {
      'name': 'Pelle/balayette',
      'rate': {'number': 1}
    },
    {
      'name': 'Seau',
      'rate': {'number': 1}
    },
    {
      'name': 'Couverture polaire',
      'rate': {'number': 1}
    }
  ],
  'orderAlphabetically': true
};

//Groupes d'équipements
const equipmentFurniture = {
  'name': 'Équipements/Meubles',
  'defaultRates': [rate.condition, rate.cleaning, rate.state, rate.number],
  'content': []
};

const equipmentToilet = {
  'name': 'Équipements WC',
  'defaultRates': [rate.condition, rate.cleaning, rate.state],
  'content': [
    {
      'name': 'Porte manteau',
      'rate': {number: 0}
    },
    {
      'name': 'Miroir',
      'rate': {number: 0}
    },
    {
      'name': 'Etagère',
      'rate': {number: 0}
    },
    {
      'name': 'Brosse à toilette',
      'rate': {number: 0}
    }
  ],
  'orderAlphabetically': true
};

const equipmentLivingroom = {
  'name': 'Équipements Salon',
  'defaultRates': [rate.condition, rate.state, rate.number],
  'content': [
    {
      'name': 'Canapé',
      'rate': {number: 0}
    },
    {
      'name': 'Fauteuil',
      'rate': {number: 0}
    },
    {
      'name': 'Table basse',
      'rate': {number: 0}
    },
    {
      'name': 'Table',
      'rate': {number: 0}
    },
    {
      'name': 'Chaise',
      'rate': {number: 0}
    },
    {
      'name': 'Tapis',
      'rate': {number: 0}
    },
    {
      'name': 'Meuble TV',
      'rate': {number: 0}
    },
    {
      'name': 'TV',
      'rate': {number: 0}
    },
    {
      'name': 'Meuble de rangement',
      'rate': {number: 0}
    },
    {
      'name': 'Décorations',
      'rate': {number: 0}
    }
  ],
  'orderAlphabetically': true
};

const equipmentKitchen = {
  'name': 'Équipements Cuisine',
  'defaultRates': [rate.condition, rate.state, rate.number],
  'content': [
    {
      'name': 'Grille pain',
      'rate': {number: 0}
    },
    {
      'name': 'Bouilloire',
      'rate': {number: 0}
    },
    {
      'name': 'Cafetière',
      'rate': {number: 0}
    },
    {
      'name': 'Égouttoir Vaisselle',
      'rate': {number: 0}
    },
    {
      'name': 'Table',
      'rate': {number: 0}
    },
    {
      'name': 'Chaise',
      'rate': {number: 0}
    },
    {
      'name': 'Poubelle',
      'rate': {number: 0}
    }
  ],
  'orderAlphabetically': true
};

const equipmentDishes = {
  'name': 'Equipements Vaisselle',
  'defaultRates': [rate.condition, rate.state, rate.number],
  'content': [
    {
      'name': 'Fourchette',
      'rate': {number: 0}
    },
    {
      'name': 'Couteau',
      'rate': {number: 0}
    },
    {
      'name': 'Couteau à viande',
      'rate': {number: 0}
    },
    {
      'name': 'Couteau de cuisine',
      'rate': {number: 0}
    },
    {
      'name': 'Cuillère à café',
      'rate': {number: 0}
    },
    {
      'name': 'Cuillère à soupe',
      'rate': {number: 0}
    },
    {
      'name': 'Grande assiette',
      'rate': {number: 0}
    },
    {
      'name': 'Assiette à dessert',
      'rate': {number: 0}
    },
    {
      'name': 'Assiette creuse',
      'rate': {number: 0}
    },
    {
      'name': 'Verre à eau',
      'rate': {number: 0}
    },
    {
      'name': 'Verre à vin',
      'rate': {number: 0}
    },
    {
      'name': 'Bol',
      'rate': {number: 0}
    },
    {
      'name': 'Mug',
      'rate': {number: 0}
    },
    {
      'name': 'Tasse à café',
      'rate': {number: 0}
    },
    {
      'name': 'Sous-coupes tasses',
      'rate': {number: 0}
    },
    {
      'name': 'Économe',
      'rate': {number: 0}
    },
    {
      'name': 'Limonadier',
      'rate': {number: 0}
    },
    {
      'name': 'Fouet',
      'rate': {number: 0}
    },
    {
      'name': 'Écumoire',
      'rate': {number: 0}
    },
    {
      'name': 'Spatule',
      'rate': {number: 0}
    },
    {
      'name': 'Louche',
      'rate': {number: 0}
    },
    {
      'name': 'Râpe',
      'rate': {number: 0}
    },
    {
      'name': 'Cuillère de service',
      'rate': {number: 0}
    },
    {
      'name': 'Casserole',
      'rate': {number: 0}
    },
    {
      'name': 'Poêle',
      'rate': {number: 0}
    },
    {
      'name': 'Fait-tout',
      'rate': {number: 0}
    },
    {
      'name': 'Couvercle',
      'rate': {number: 0}
    },
    {
      'name': 'Ramequin',
      'rate': {number: 0}
    },
    {
      'name': 'Tupperware',
      'rate': {number: 0}
    },
    {
      'name': 'Verre doseur',
      'rate': {number: 0}
    },
    {
      'name': 'Saladier',
      'rate': {number: 0}
    },
    {
      'name': 'Passoire',
      'rate': {number: 0}
    },
    {
      'name': 'Plat en verre',
      'rate': {number: 0}
    },
    {
      'name': 'Plat en céramique',
      'rate': {number: 0}
    },
    {
      'name': 'Plat à tarte',
      'rate': {number: 0}
    },
    {
      'name': 'Repose-plat',
      'rate': {number: 0}
    }
  ],
  'orderAlphabetically': false
};

const equipmentBedroom = {
  'name': 'Équipements Chambre',
  'defaultRates': [rate.condition, rate.state, rate.number],
  'content': [
    {
      'name': 'Lit',
      'rate': {number: 0}
    },
    {
      'name': 'Matelas',
      'rate': {number: 0}
    },
    {
      'name': 'Couette',
      'rate': {number: 0}
    },
    {
      'name': 'Alèse',
      'rate': {number: 0}
    },
    {
      'name': 'Oreiller',
      'rate': {number: 0}
    },
    {
      'name': 'Draps',
      'rate': {number: 0}
    },
    {
      'name': 'Table de chevet',
      'rate': {number: 0}
    },
    {
      'name': 'Lampe',
      'rate': {number: 0}
    },
    {
      'name': 'Bureau',
      'rate': {number: 0}
    },
    {
      'name': 'Chaise',
      'rate': {number: 0}
    },
    {
      'name': 'Meuble de rangement',
      'rate': {number: 0}
    },
    {
      'name': 'Corbeille',
      'rate': {number: 0}
    },
    {
      'name': 'Cintre',
      'rate': {number: 0}
    },
    {
      'name': 'Tringle',
      'rate': {number: 0}
    },
    {
      'name': 'Rideau',
      'rate': {number: 0}
    },
    {
      'name': 'Miroir',
      'rate': {number: 0}
    }
  ],
  'orderAlphabetically': true
};

const equipmentBathroom = {
  'name': 'Équipements SDB',
  'defaultRates': [rate.condition, rate.cleaning, rate.state, rate.number],
  'content': [
    {
      'name': 'Porte manteau',
      'rate': {number: 0}
    },
    {
      'name': 'Miroir',
      'rate': {number: 0}
    },
    {
      'name': 'Étagère',
      'rate': {number: 0}
    },
    {
      'name': 'Porte serviette',
      'rate': {number: 0}
    },
    {
      'name': 'Serviette',
      'rate': {number: 0}
    },
    {
      'name': 'Tapis de bain',
      'rate': {number: 0}
    },
    {
      'name': 'Gobelet',
      'rate': {number: 0}
    },
    {
      'name': 'Pousse-mousse / Porte-savon',
      'rate': {number: 0}
    },
    {
      'name': 'Sèche-cheveux',
      'rate': {number: 0}
    },
    {
      'name': 'Petite poubelle',
      'rate': {number: 0}
    }
  ],
  'orderAlphabetically': true
};

const equipmentCleaning = {
  'name': 'Équipement Ménage',
  'defaultRates': [rate.condition, rate.state, rate.number],
  'content': [
    {
      'name': 'Balai',
      'rate': {number: 0}
    },
    {
      'name': 'Balai brosse',
      'rate': {number: 0}
    },
    {
      'name': 'Pelle et balayette',
      'rate': {number: 0}
    },
    {
      'name': 'Seau',
      'rate': {number: 0}
    },
    {
      'name': 'Serpillière',
      'rate': {number: 0}
    },
    {
      'name': 'Aspirateur',
      'rate': {number: 0}
    }
  ],
  'orderAlphabetically': true
};

const cleaning = {
  'name': 'Ménage',
  'defaultRates': [rate.cleaning],
};

const photos = {
  'name': 'Photos',
  'cantBeAdded': true, // Ne peut pas être ajouté manuellement
  'defaultRates': [rate.photo],
  'content': []
};

const entries = {
  walls,
  floor,
  ceiling,
  plinths,
  door,
  windows,
  storage,
  mezzanine,
  steps,
  railing,
  radiators,
  ventilation,
  electricSystemSimple,
  electricSystemComplex,
  tiles,
  sinkComplex,
  bathroomSinkComplex,
  toiletComplex,
  equippedKitchen,
  handWash,
  bathtub,
  shower,
  fence,
  lawn,
  vegetation,
  smokeDetector,
  letterbox,
  letterboxLock,
  intercom,
  doorbell,
  hotwaterHeating,
  heating,
  electricMeter,
  hotwaterMeter,
  keys,
  fuseboard,
  kitAlurT1,
  kitAlurT2,
  equipmentFurniture,
  equipmentToilet,
  equipmentLivingroom,
  equipmentKitchen,
  equipmentDishes,
  equipmentBedroom,
  equipmentBathroom,
  equipmentCleaning,
  cleaning,
  photos
};

export const all = Object.values(entries);

export const addableEntries = all
  .filter(entry => entry.cantBeAdded === undefined || entry.cantBeAdded === false)
  .sort((a,b) => a.name.localeCompare(b.name));

//Default is true
//Can be set to false with "canHaveCustomSubEntries: false" in entryDefinition
export const canHaveCustomSubEntries = (entryToValidate) => {
  let entryToValidateDefinition = all.find(entry => entry.name === entryToValidate.name);
  return entryToValidateDefinition !== undefined ?
    (entryToValidateDefinition.canHaveCustomSubEntries !== undefined ? entryToValidateDefinition.canHaveCustomSubEntries : true)
    : false;
}

const getRateVariable = (rate) => {
  return rate.rateVariable !== undefined ? rate.rateVariable : rate.name;
};

export const getEntryVariableFromName = (entryName) => {
  return _.findKey(entries,(entry) => {
    return entry.name === entryName;
  });
}

export const build = (entry, rates = null) => {
  const configurationProperties = ['defaultRates', 'cantBeAdded', 'orderAlphabetically', 'whiteListedModules', 'templates', 'addToBaseVersion'];
  const builtEntry = structuredClone(entry);

  if(rates) { //Override default rates
    rates.forEach((rate) => {
      if(builtEntry.content !== undefined) {
        builtEntry.content.forEach((subEntry) => {
          fillEntryRate(subEntry, rate);
        });
      } else {
        fillEntryRate(builtEntry, rate);
      }
    });
  } else { //use default rates defined in entry
    if(builtEntry !== undefined && builtEntry.content !== undefined) {
      builtEntry.content.forEach((subEntry) => {
        if(subEntry.defaultRates !== undefined) {
          subEntry.defaultRates.forEach((rate) => {
            fillEntryRate(subEntry, rate);
          });
        } else if (builtEntry.defaultRates !== undefined) {
          builtEntry.defaultRates.forEach((rate) => {
            fillEntryRate(subEntry, rate);
          });
        }
      });
    } else if(builtEntry !== undefined) {
      if(builtEntry.defaultRates !== undefined) {
        builtEntry.defaultRates.forEach((rate) => {
          fillEntryRate(builtEntry, rate);
        });
      }
    }
  }

  //remove configuration properties which are not useful once in EDL
  configurationProperties.forEach((property) => {
    if(builtEntry !== undefined && builtEntry[property] !== undefined) {
      delete builtEntry[property];
    }
  });

  return builtEntry;
};

export const fillEntryRate = (entry, rate) => {
  if (entry.rate === undefined) {
    entry.rate = {};
  }
  entry.rate[getRateVariable(rate)] = null;
  if (rate.rateVariable !== undefined) {
    if (entry.rateVariables === undefined) {
      entry.rateVariables = {};
    }
    entry.rateVariables[getRateVariable(rate)] = rate.name;
  }
}

export default entries;
