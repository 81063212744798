<template>
  <f7-page
    no-swipeback
    :page-content="false"
  >
    <navigation-top
      title="Fiche groupe"
      back="userGroups"
    />

    <navigation-bottom current="account" />

    <f7-page-content v-if="userGroup">
      <f7-block class="text-align-center">
        <img
          v-if="userGroup.logo"
          :src="userGroup.logo"
          class="logo"
        >
        <f7-icon
          v-else
          f7="person_crop_circle_badge_plus"
          size="80"
        />
        <input
          ref="logoInput"
          class="file-input"
          type="file"
          accept="image/*"
          @change="uploadLogo"
        >
      </f7-block>

      <f7-list inset>
        <f7-list-item
          header="Nom du groupe"
          :title="userGroup.name"
          after="Modifier"
          :class="{disabled: !isOnline}"
          @click="updateName"
        >
          <template #media>
            <f7-icon f7="envelope" />
          </template>
        </f7-list-item>
        <f7-list-item
          v-if="isSuperAdmin"
          link="#"
          header="Groupe parent"
          after="Sélectionner"
          smart-select
          :smart-select-params="{
            openIn: 'popup',
            searchbar: true,
            searchbarPlaceholder: 'Recherchez un groupe',
            closeOnSelect: true,
            scrollToSelectedItem: true}"
        >
          <select
            v-if="groups"
            name="groups"
            :value="userGroup.parentId"
            @change="updateParentId"
          >
            <option value="">
              ----- Aucun groupe -----
            </option>
            <option
              v-for="group in groups"
              :key="group.id"
              :value="group.id"
              :disabled="group.id === userGroup.id"
              :selected="group.id === userGroup.parentId"
            >
              {{ group.name }}
            </option>
          </select>
          <template #media>
            <f7-icon f7="person_3" />
          </template>
        </f7-list-item>
        <f7-list-button @click="goToUsers">
          Voir les utilisateurs du groupe
          <f7-icon f7="arrow_up_right_square" />
        </f7-list-button>
      </f7-list>
      <f7-block-title>Paramétrage du groupe</f7-block-title>
      <f7-list inset>
        <f7-list-item
          title="Modifier le logo"
          after="Format attendu : 150x150"
          class="list-action"
          :class="{disabled: !isOnline}"
          @click="updateLogo"
        >
          <template #media>
            <f7-icon f7="camera_on_rectangle_fill" />
          </template>
        </f7-list-item>

        <f7-list-item
          class="list-action"
          :class="{disabled: !isOnline}"
          :link="f7router.generateUrl({name: 'userGroupCostingCalibration', params: {id}})"
          title="Calibrage de l'auto-chiffrage"
        >
          <template #media>
            <f7-icon f7="money_euro_circle" />
          </template>
        </f7-list-item>

        <f7-list-item
          class="list-action"
          :class="{disabled: !isOnline}"
          title="Personnaliser les mentions légales"
          @click="showCustomLegalTextPopup = true"
          link
        >
          <template #media>
            <f7-icon f7="paragraph" />
          </template>
        </f7-list-item>
        <f7-list-input
          class="list-action"
          type="colorpicker"
          :class="{disabled: !isOnline}"
          label="Personnaliser la couleur principale des EDLs du groupe (en cliquant sur le code)"
          :clear-button="isCustomColor"
          :value="inputCustomColorObject"
          :color-picker-params="{modules:['wheel','hex'], hexValueEditable: true}"
          @colorpicker:change="updateCustomColor"
          @input:clear="removeCustomColor"
        >
          <template #media>
            <f7-icon f7="eyedropper" />
          </template>
          <template #info>
            <span v-if="isDefaultColor">
              Couleur par défaut de DDH
            </span>
            <span v-else-if="isParentColor">
              Couleur par défaut de {{ userGroup.parent.name }}
            </span>
            <span v-else>
              Couleur personnalisée (cliquer la croix à droite pour revenir à la couleur par défaut)
            </span>
          </template>
          <template #content-start>
            <div
              style="height: 15px; width: 15px; display: inline-block; position: absolute; left: 35px; top: 40px;"
              :style="{backgroundColor: '#'+currentCustomColor}"
            ></div>
          </template>
        </f7-list-input>
      </f7-list>
      <f7-block-title>Options du groupe</f7-block-title>
      <f7-list inset>
        <f7-list-item
          header="Désactiver la modification des mots de passe par l’utilisateur"
          :footer="userGroup.parent?.lockPasswords ? 'Activé groupe parent' : ''"
          :class="{disabled: !isOnline || userGroup.parent?.lockPasswords}"
        >
          <template #media>
            <f7-icon f7="lock_slash_fill" />
          </template>
          <f7-toggle
            :checked="userGroup.lockPasswords"
            @toggle:change="updateLockPasswords"
          />
        </f7-list-item>
        <f7-list-item
          header="Partager les EDLs entre chaque utilisateur du groupe"
          :footer="userGroup.parent?.shareEdls ? 'Activé groupe parent' : ''"
          :class="{disabled: !isOnline || userGroup.parent?.shareEdls}"
        >
          <template #media>
            <f7-icon f7="person_2_square_stack" />
          </template>
          <f7-toggle
            :checked="userGroup.shareEdls"
            @toggle:change="updateShareEdls"
          />
        </f7-list-item>
        <f7-list-item
          header="Limiter les utilisateurs du groupe à un seul profil"
          :footer="userGroup.parent?.uniqueProfile ? 'Activé groupe parent' : ''"
          :class="{disabled: !isOnline || userGroup.parent?.uniqueProfile}"
        >
          <template #media>
            <f7-icon f7="person_alt_circle" />
          </template>
          <f7-toggle
            :checked="userGroup.uniqueProfile"
            @toggle:change="updateUniqueProfile"
          />
        </f7-list-item>
        <f7-list-item
          header="Afficher les EDLs de tous les profils par défaut dans la liste des EDLs sauvegardés"
          :footer="userGroup.parent?.defaultListAllProfiles ? 'Activé groupe parent' : ''"
          :class="{disabled: !isOnline || userGroup.parent?.defaultListAllProfiles}"
        >
          <template #media>
            <f7-icon f7="person_2_alt" />
          </template>
          <f7-toggle
            :checked="userGroup.defaultListAllProfiles"
            @toggle:change="updateDefaultListAllProfiles"
          />
        </f7-list-item>
      </f7-list>

      <f7-list class="delete-section" inset>
        <f7-list-button
          title="Supprimer le groupe"
          color="red"
          :class="{disabled: !isOnline}"
          @click="this.delete"
        />
      </f7-list>
      <p class="delete-info" v-if="userGroup.parent === undefined || userGroup.parent === null">⚠︎ La suppression de ce groupe parent entrainera une suppression de tous ses utilisateurs, de ses sous-groupes et de leurs utilisateurs, suivi d'une anonymisation de tous leurs EDLs.</p>
      <p class="delete-info" v-else>⚠︎ En supprimant ce sous-groupe, tous ses utilisateurs et leurs EDLs seront transférés vers le groupe parent.</p>
    </f7-page-content>

    <custom-legal-text-popup
      :opened="showCustomLegalTextPopup"
      :edl="userGroup?.customLegalText"
      :convention="userGroup?.customLegalTextConvention"
      :context="isSubgroup ? 'subgroup' : 'group'"
      @update="updateCustomLegalTexts"
      @close="showCustomLegalTextPopup = false"
    />
  </f7-page>
</template>

<script>
  import firewall from "../../../mixins/firewall";
  import {mapActions, mapGetters} from "vuex";
  import NavigationTop from "../../../layout/NavigationTop.vue";
  import NavigationBottom from "../../../layout/NavigationBottom.vue";
  import {clone} from "lodash";
  import { f7 } from "framework7-vue";
  import {nextTick} from "vue";
  import logo from "@/components/mixins/logo";
  import offline from "@/components/mixins/offline";
  import CustomLegalTextPopup from "@/components/pages/User/CustomLegalTextPopup.vue";

  const DEFAULT_COLOR = '99c46a';
  export default {
    name: "UserGroupShow",
    components: {CustomLegalTextPopup, NavigationBottom, NavigationTop},
    mixins: [firewall, logo, offline],
    props: {
      id: {
        type: Number,
        default: null
      },
      f7router: {
        type: Object,
        default: null
      }
    },
    data() {
      return {
        showCustomLegalTextPopup: false,
        localCustomColor: null,
        localCustomColorCleared: false
      }
    },
    computed: {
      ...mapGetters('userGroup', {
        'groups': 'getRootUserGroups',
        'getUserGroupById': 'getUserGroupById',
      }),
      userGroup() {
        return this.getUserGroupById(this.id);
      },
      isSubgroup() {
        return this.userGroup?.parent !== undefined && this.userGroup?.parent !== null;
      },
      currentCustomColor: function () {
        return this.userGroup.currentCustomColor ?? DEFAULT_COLOR;
      },
      inputCustomColor: function () {
        if(this.localCustomColorCleared) {
          return this.currentCustomColor;
        }
        return this.localCustomColor ? this.localCustomColor : (this.userGroup.customColor ?? this.currentCustomColor);
      },
      inputCustomColorObject: function () {
        return {hex: '#'+this.inputCustomColor};
      },
      isCustomColor: function () {
        return this.userGroup.currentCustomColorOrigin === 'group';
      },
      isDefaultColor: function () {
        return this.currentCustomColor === DEFAULT_COLOR && this.userGroup.currentCustomColorOrigin === 'ddh';
      },
      isParentColor: function () {
        return !this.isCustomColor && this.userGroup.currentCustomColorOrigin === 'parent';
      }
    },
    mounted() {
      nextTick(() => {
        this.limitToAdmins();

        f7.preloader.show();

        Promise.all([
          this.fetchUserGroup(parseInt(this.id)),
          this.fetchUserGroups()
        ]).finally(() => f7.preloader.hide());
      });
    },
    methods: {
      ...mapActions('userGroup', [
        'fetchUserGroup',
        'updateUserGroup',
        'deleteUserGroup',
        'fetchUserGroups',
      ]),
      delete() {
        f7.dialog.confirm('Êtes-vous certains de vouloir supprimer ce groupe utilisateur&nbsp;? Cette action est définitive.',
          () => {
            f7.dialog.preloader('Suppression...');
            this.deleteUserGroup(this.userGroup)
              .then(() => {
                f7.dialog.close()
                this.f7router.navigate({name: 'userGroups'})
              })
              .catch(() => f7.dialog.alert('Impossible de supprimer le groupe'))
          },
        )
      },
      updateName() {
        f7.dialog.prompt('Nouveau nom',
          (value) => {
            f7.dialog.preloader('Enregistrement...');
            let newUserGroup = clone(this.userGroup);
            newUserGroup.name = value;
            this.updateUserGroup({ id: this.userGroup.id, payload: newUserGroup })
              .then(() => {
                f7.dialog.close();
                this.userGroup.name = value;
              })
              .catch(() => f7.dialog.alert('Le nom n\'a pas pu être mise à jour'))
          },
          null,
          this.userGroup.name
        )
      },
      updateLockPasswords(value) {
        let newUserGroup = clone(this.userGroup);
        newUserGroup.lockPasswords = value;
        this.updateUserGroup({ id: this.userGroup.id, payload: newUserGroup })
          .then(() => {
            this.userGroup.lockPasswords = value;
          })
          .catch(() => f7.dialog.alert('Il y a eu une erreur pour activer cette option'))
      },
      updateShareEdls(value) {
        let newUserGroup = clone(this.userGroup);
        newUserGroup.shareEdls = value;
        this.updateUserGroup({ id: this.userGroup.id, payload: newUserGroup })
          .then(() => {
            this.userGroup.shareEdls = value;
          })
          .catch(() => f7.dialog.alert('Il y a eu une erreur pour activer cette option'))
      },
      updateUniqueProfile(value) {
        let newUserGroup = clone(this.userGroup);
        newUserGroup.uniqueProfile = value;
        this.updateUserGroup({ id: this.userGroup.id, payload: newUserGroup })
          .then(() => {
            this.userGroup.uniqueProfile = value;
          })
          .catch(() => f7.dialog.alert('Il y a eu une erreur pour activer cette option'))
      },
      updateDefaultListAllProfiles(value) {
        let newUserGroup = clone(this.userGroup);
        newUserGroup.defaultListAllProfiles = value;
        this.updateUserGroup({ id: this.userGroup.id, payload: newUserGroup })
          .then(() => {
            this.userGroup.defaultListAllProfiles = value;
          })
          .catch(() => f7.dialog.alert('Il y a eu une erreur pour activer cette option'))
      },
      updateParentId(event) {
        let value = parseInt(event.target.value);
        let newUserGroup = clone(this.userGroup);
        newUserGroup.parentId = value;
        this.updateUserGroup({ id: this.userGroup.id, payload: newUserGroup })
          .then(() => {
            this.userGroup.parentId = value;
          })
          .catch(() => f7.dialog.alert('Il y a eu une erreur pour modifier le groupe parent'))
      },
      updateCustomLegalTexts(payload) {
        let newUserGroup = clone(this.userGroup);
        newUserGroup.customLegalText = payload.edl;
        newUserGroup.customLegalTextConvention = payload.convention;
        f7.dialog.preloader('Enregistrement des mentions légales...');
        this.updateUserGroup({ id: this.userGroup.id, payload: newUserGroup }).then(() => {
          f7.dialog.close();
          f7.dialog.create({
            title: null,
            text: "<strong>Les mentions légales du groupe ont bien été mises à jour.</strong><br/><br/>Elles figureront<sup>*</sup> sur les nouveaux PDFs des utilisateurs du groupe (et de ses éventuels sous-groupe),<br/>ou en re-générant les PDFs des EDLs existants.<br/><br/><sup>*</sup>sauf si les utilisateurs ou le sous-groupes personnalisent leurs mentions légales aussi.",
            buttons: [
              {
                text: 'OK',
                bold: true,
                close: true
              }
            ]
          }).open();
        })
      },
      updateCustomColor(value) {
        let newUserGroup = clone(this.userGroup);
        const newColor = value.hex.replace('#', '');
        this.localCustomColor = newColor;
        if(this.localCustomColor === this.currentCustomColor) { // to avoid the default color being saved
          return;
        }
        f7.dialog.preloader('Enregistrement...');
        newUserGroup.customColor = newColor;
        this.updateUserGroup({id: this.userGroup.id, payload: newUserGroup})
          .then(() => f7.dialog.close())
          .catch(() => f7.dialog.alert('Une erreur s\'est produite durant la mise à jour de la couleur'));
      },
      removeCustomColor() {
        this.localCustomColorCleared = true;
        f7.dialog.preloader('Enregistrement...');
        let newUserGroup = clone(this.userGroup);
        newUserGroup.customColor = '';
        this.updateUserGroup({id: this.userGroup.id, payload: newUserGroup})
          .then(() => {
            f7.dialog.close();
            this.localCustomColor = null;
            this.localCustomColorCleared = false;
          })
          .catch(() => f7.dialog.alert('Une erreur s\'est produite durant la mise à jour de la couleur'));
      },
      doUploadLogo(payload) { //See logo mixin
        let newUserGroup = clone(this.userGroup);
        newUserGroup.logo = payload.logo;
        return this.updateUserGroup({id: this.userGroup.id, payload: newUserGroup}).then(() => {
          this.fetchUserGroup(parseInt(this.id)).then((data) => this.userGroup = data);
        });
      },
      goToUsers() {
        let url = this.f7router.generateUrl({name: 'userListGroup', params: {id: this.userGroup.id}});
        window.open('#'+url, '_blank');
      }
    }
  }
</script>

<style lang="scss" scoped>
  .logo {
    height: 150px;
    width: 150px;
  }
  .file-input {
    display: none;
  }
  .delete-section {
    margin-top: 100px;
  }
  .delete-info {
    position: relative;
    font-size: 10px;
    color: grey;
    text-align: center;
    margin-top: -10px;
    width: 80%;
    margin-left: 10%;
  }
</style>
