import api from '../../api';
import {unobserve} from "@/store/helper";

// initial state
const state = () => ({
  userGroups: [],
})

// getters
const getters = {
  getAllUserGroups: state => state.userGroups,
  getAllChildrenOfGroupId: state => (id, self) => {
    let groups = state.userGroups.filter(userGroup => userGroup.parent !== undefined ? userGroup.parent.id === id : false);
    if(self) {
      let selfGroup = state.userGroups.find(userGroup => userGroup.id === id)
      if(selfGroup) {
        groups.unshift(selfGroup);
      }
    }
    return groups;
  },
  getUserGroupById: state => id => state.userGroups.find(userGroup => parseInt(userGroup.id) === parseInt(id)),
  getUserGroupsByParentId: state => id => state.userGroups.filter(userGroup => userGroup.parent !== undefined ? userGroup.parent.id === id : false),
  getRootUserGroups: state => state.userGroups.filter(userGroup => userGroup.parentId === undefined || userGroup.parentId === null),
  getHierarchicalUserGroups: state => {
    let groups = state.userGroups.filter(userGroup => userGroup.parentId === undefined || userGroup.parentId === null)
    let groupsWithChildren = unobserve(groups);
    groupsWithChildren.forEach((value, index, array) => {
      array[index]['children'] = state.userGroups.filter(userGroup => userGroup.parentId === value.id)
    });
    return groupsWithChildren;
  }
}

// actions
const actions = {
  fetchUserGroups({commit}) {
    return api.userGroups()
      .then((response) => {
        if(response) {
          commit('pushUserGroups', response.data);
        }
      });
  },
  fetchUserGroup({commit}, id) {
    return api.userGroup(id)
      .then((response) => {
        if(response) {
          commit('pushUserGroup', response.data);
          return response.data;
        } else {
          return null;
        }
      });
  },
  createUserGroup({commit}, payload) {
    return api.createUserGroup(payload)
      .then((response) => {
        if(response) {
          let userGroup = response.data;
          commit('pushUserGroup', userGroup);

          return userGroup;
        } else {
          return null;
        }
      });
  },
  updateUserGroup({commit}, {id, payload}) {
    return api.updateUserGroup(id, payload)
      .then((response) => {
        console.log('then updateUserGroup', response.data);
        let userGroup = response.data
        commit('pushUserGroup', userGroup)

        return userGroup
      })
  },
  deleteUserGroup({commit}, userGroup) {
    return api.deleteUserGroup(userGroup.id)
      .then(() => {
        commit('popUserGroup', userGroup);
      })
    ;
  },
};

// mutations
const mutations = {
  pushUserGroups(state, userGroups) {
    state.userGroups = state.userGroups.filter(stateUserGroup => ! userGroups.find (newUserGroup => stateUserGroup.id === newUserGroup.id) ).concat(userGroups);
  },
  pushUserGroup(state, userGroup) {
    let index = state.userGroups.findIndex(item => item.id === userGroup.id)

    if (index >= 0)
      state.userGroups.splice(index, 1, userGroup)
    else
      state.userGroups.push(userGroup)
  },
  popUserGroup(state, userGroup) {
    state.userGroups.splice(state.userGroups.findIndex(item => item.id === userGroup.id), 1);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
