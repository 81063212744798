<template>
  <f7-row ref="recap">
    <f7-col>
      <f7-row
        class="current-module"
      >
        <f7-col>
          <f7-block-header
            v-if="currentModule"
            class="header-module"
          >
            <i
              class="ddh-icons grey"
              :class="getModuleIcon(currentModule)"
            />
            {{ $t(currentModule) }}
          </f7-block-header>
        </f7-col>
      </f7-row>
      <f7-row
        v-for="module in edl"
        :key="module.id"
        ref="modules"
        :header="$t(module.name)"
      >
        <f7-col>
          <f7-block-header class="header-module">
            <i
              class="ddh-icons grey"
              :class="getModuleIcon(module.name)"
            />
            {{ $t(module.name) }}
          </f7-block-header>
          <f7-block>
            <f7-row
              v-for="entry in module.entry"
              :key="module.id+'_'+entry.id"
              class="entry"
            >
              <f7-col v-if="entry.content === undefined">
                <f7-block-header class="header-entry">
                  {{ $t(entry.name) }}
                </f7-block-header>
                <f7-row
                  v-for="(rate, rateName) in filterRates(entry.rate)"
                  :key="module.id+'_'+entry.id+'_'+rateName"
                >
                  <f7-col class="rate-name">
                    {{ $t(rateLabel(rateName)) }}
                  </f7-col>
                  <f7-col
                    v-if="rate"
                    class="rate nl2br"
                  >
                    {{ tryTranslate(rate) }}
                  </f7-col>
                  <f7-col
                    v-else
                    class="rate"
                  >
                    -
                  </f7-col>
                </f7-row>
                <!-- entry context medias -->
                <f7-row
                  v-if="hasMedias(getContextPath(module.id, entry.id))"
                  class="photos photos-context justify-content-flex-start"
                >
                  <f7-block-header>Photos</f7-block-header>
                  <f7-col
                    v-for="(subEntry, subEntryIndex) in getMedias(getPhotosEntry(module), getContextPath(module.id, entry.id))"
                    :key="module.id+'_'+entry.id+'_photo'+subEntryIndex"
                    width="100"
                    small="50"
                    medium="33"
                    class="photo"
                  >
                    <img
                      v-photo="getMedia(subEntry)"
                      width="44"
                    >
                    <p
                      v-if="subEntry.rate.other !== undefined && subEntry.rate.other !== null"
                      class="nl2br"
                    >
                      {{ subEntry.rate.other }}
                    </p>
                  </f7-col>
                </f7-row>
              </f7-col>

              <!-- photos without contexts -->
              <f7-col
                v-else-if="entry.name === 'Photos'"
                v-show="entry.content.length > 0"
              >
                <f7-block-header
                  v-if="entry.content.length > 0"
                  class="header-entry"
                >
                  {{ $t(entry.name) }}
                </f7-block-header>
                <f7-row
                  v-if="entry.content.length > 0"
                  class="photos justify-content-flex-start"
                >
                  <f7-col
                    v-for="(subEntry, subEntryIndex) in getMedias(entry)"
                    :key="module.id+'_'+entry.id+'_photo'+subEntryIndex"
                    width="100"
                    small="50"
                    medium="33"
                    class="photo"
                  >
                    <img
                      v-photo="getMedia(subEntry)"
                      width="44"
                    >
                    <p
                      v-if="subEntry.rate.other !== undefined && subEntry.rate.other !== null"
                      class="nl2br"
                    >
                      {{ subEntry.rate.other }}
                    </p>
                  </f7-col>
                </f7-row>
              </f7-col>

              <f7-col v-else>
                <f7-row
                  v-for="subEntry in entry.content"
                  :key="module.id+'_'+entry.id+'_'+subEntry.id"
                  class="sub-entry"
                >
                  <f7-col>
                    <f7-block-header class="header-entry">
                      {{ $t(entry.name) }} <f7-icon f7="chevron_right" size="14px" style="height: 16px"></f7-icon> <span class="header-sub-entry">{{ $t(subEntry.name) }}</span>
                    </f7-block-header>
                    <f7-row
                      v-for="(rate, rateName) in filterRates(subEntry.rate)"
                      :key="module.id+'_'+entry.id+'_'+subEntry.id+'_'+rateName"
                    >
                      <f7-col class="rate-name">
                        {{ $t(rateLabel(rateName)) }}
                      </f7-col>
                      <f7-col
                        v-if="rate"
                        class="rate nl2br"
                      >
                        {{ tryTranslate(rate) }}
                      </f7-col>
                      <f7-col
                        v-else
                        class="rate"
                      >
                        -
                      </f7-col>
                    </f7-row>
                    <!-- subentry context medias -->
                    <f7-row
                      v-if="hasMedias(getContextPath(module.id, entry.id, subEntry.id))"
                      class="photos photos-context justify-content-flex-start"
                    >
                      <f7-block-header>Photos</f7-block-header>
                      <f7-col
                        v-for="(photoSubEntry, photoSubEntryIndex) in getMedias(getPhotosEntry(module), getContextPath(module.id, entry.id, subEntry.id))"
                        :key="module.id+'_'+entry.id+'_'+subEntry.id+'_photo'+photoSubEntryIndex"
                        width="100"
                        small="50"
                        medium="33"
                        class="photo"
                      >
                        <img
                          v-photo="getMedia(photoSubEntry)"
                          width="44"
                        >
                        <p
                          v-if="photoSubEntry.rate.other !== undefined && photoSubEntry.rate.other !== null"
                          class="nl2br"
                        >
                          {{ photoSubEntry.rate.other }}
                        </p>
                      </f7-col>
                    </f7-row>
                  </f7-col>
                </f7-row>
              </f7-col>
            </f7-row>
          </f7-block>
        </f7-col>
      </f7-row>
      <f7-row v-if="info && info.comment !== undefined">
        <f7-col>
          <f7-block-header class="header-module">
            {{ $t('Commentaire général') }}
          </f7-block-header>
          <f7-block>
            <p class="nl2br">
              {{ info.comment }}
            </p>
          </f7-block>
        </f7-col>
      </f7-row>
    </f7-col>
  </f7-row>
</template>

<script>
import _ from "lodash";
import {getConfig} from "@/data/edlDataStructureModuleEntryRate";
import hasMedia from "@/components/mixins/hasMedia";
import {PhotoDatabase} from "@/composables/photoStorage";
import {autoEdlFilteredRatesByIndex, autoEdlFilteredRatesByLabel} from "@/data/edlDataBuilder";
import autoEdl from "@/components/mixins/autoEdl";
import language from "@/components/mixins/language";
import {getModuleIcon} from "@/data/edlDataStructureModule";

const photoDb = new PhotoDatabase();

export default {
    name: "EdlRecapContent",
    directives: {
      photo: {
        beforeMount: function(el, binding) {
          let indexedDbId = binding.value;
          if (indexedDbId) {
            photoDb.getPhoto(indexedDbId).then((photo) => {
              if(photo) {
                el.src = photo.data;
              } else {
                console.error("(1) Couldn't load photo for indexedDb id "+indexedDbId);
              }
            }).catch((error) => {
              console.error("(2) Couldn't load photo for indexedDb id "+indexedDbId, error);
            });
          }
        }
      }
    },
    mixins: [hasMedia, autoEdl, language],
    props: {
      edl: {
        type: Object,
        default: null
      },
      info: {
        type: Object,
        default: null
      },
      scroll: {
        type: Number,
        default: 0
      }
    },
    emits: ['track'],
    data: function () {
      return {
        contextsWithPhotos: [],
        modulePositions: {},
        currentModule: null,
        moduleHeaderInterval: null
      }
    },
    watch: {
      scroll: {
        handler: 'updateCurrentModule'
      },
      edl: {
        handler: 'setup',
        immediate: true
      }
    },
    beforeUnmount: function () {
      window.removeEventListener('resize', this.setupCurrentModuleHeader);
      clearInterval(this.moduleHeaderInterval);
    },
    methods: {
      getModuleIcon,
      rateLabel: function (name) {
        if(name === 'other') {
          return "Observations";
        }
        if (_.startsWith(name, 'typeCustom')) {
          name = 'typeCustom$';
        }
        let config = getConfig(name);
        return config ? config.label : name;
      },
      setup: function () {
        if(this.edl) {
          this.loadContextPhotos();
          this.moduleHeaderInterval = setInterval(() => {
            this.setupCurrentModuleHeader();
          }, 3000);
          this.$emit('track');
        }
      },
      setupCurrentModuleHeader: function () {
        let modules = this.$refs.modules;
        this.modulePositions = _.reduce(modules, (positions, module) => {
          let name = module.$attrs.header;
          positions[name] = module.$el.offsetTop;
          return positions;
        }, {});
        clearInterval(this.moduleHeaderInterval);
      },
      loadContextPhotos: function () {
        photoDb.getPhotosWithContextByLocalEdlId(this.info.localId).then((photos) => {
          this.contextsWithPhotos = photos.map((photo) => photo.context);
          window.addEventListener('resize', this.setupCurrentModuleHeader);
        });
      },
      getMedia: function (entry) {
        return _.has(entry, 'rate.photo') ? this.getPhotoId(entry.rate.photo) : null;
      },
      getMedias: function (photosEntry, contextPath = null) {
        if(!contextPath) {
          return _.filter(photosEntry.content, (photoEntry) => {
            if(photoEntry.rate.photo !== null) {
              return photoEntry.rate.photo._context === undefined
            } else {
              return false;
            }
          });
        } else {
          return _.filter(photosEntry.content, (photoEntry) => {
            if(photoEntry.rate.photo !== null && photoEntry.rate.photo._context !== undefined) {
              return photoEntry.rate.photo._context === contextPath;
            } else {
              return false;
            }
          });
        }
      },
      hasMedias: function (context) {
        return this.contextsWithPhotos.includes(context);
      },
      getContextPath: function (moduleId, entryId, subEntryId = null) {
        let path = [moduleId, entryId];
        if(subEntryId !== null) {
          path.push(subEntryId);
        }
        return path.join('.');
      },
      getPhotosEntry: function (module) {
        return _.find(module.entry, (entry) => entry.name === "Photos");
      },
      updateCurrentModule: function () {
        let current = _.reduce(this.modulePositions, (max, position, name) => {
          if(position > this.scroll) {
            return max;
          } else if(max === null) {
            return {name, position};
          } else if(max.position < position) {
            return {name, position};
          } else {
            return max;
          }
        }, null);
        this.currentModule = current ? current.name : null;
      },
      filterRates: function (rates) {
        return this.filterCaseAutoEdl(this.filterQuotes(rates));
      },
      filterQuotes: function (rates) {
        return _.pickBy(rates, (rate, index) => {
          return index !== 'toQuote' && index !== 'preQuote';
        })
      },
      filterCaseAutoEdl: function (rates) {
        if(this.autoEdl) {
          return _.pickBy(rates, (rate, index) => {
            if(autoEdlFilteredRatesByIndex.includes(index)) {
              return false;
            } else {
              let config = getConfig(index);
              if(config !== undefined && autoEdlFilteredRatesByLabel.includes(config.label)) {
                return false;
              }
            }
            return true;
          });
        } else {
          return rates;
        }
      },
      tryTranslate: function (rate) {
        if(_.isString(rate)) {
          return this.$t(rate);
        } else {
          return rate;
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .current-module {
    position: fixed;
    height: 50px;
    width: 100%;
    z-index: 100;
    .header-module {
      margin: 0;
    }
  }
  .header-module {
    background: var(--f7-theme-color-grey);
    font-size: 34px;
  }
  .block .header-entry {
    width: 30%;
    position: relative;
    left: -20px;
    font-size: 18px;
    padding-left: calc(var(--f7-block-padding-horizontal) + var(--f7-safe-area-left) + 4px);
    background-color: var(--f7-theme-color-dark-grey);
    color: white;
    font-weight: bold;
    border-radius: 0 15px 15px 0;
  }
  .header-sub-entry {
    color: white;
    font-weight: normal;
  }
  .entry {
    margin-bottom: 25px;
  }
  .entry .sub-entry:not(:last-child) {
    margin-bottom: 25px;
  }
  .rate-name {
    font-weight: bold;
    font-size: 16px;
  }
  .rate {
    font-size: 16px;
  }
  .photos {
    padding: 5px;
    border-radius: 5px;
    background-color: white;
    :deep(.block-header) {
      padding: 10px 0 0 10px;
      width: 100%;
      margin: 0;
    }
    .photo {
      padding: 1%;
      float: left;
      height: auto;
      img {
        width: 100%; /* or any custom size */
        height: auto;
        object-fit: contain;
      }
    }
  }
  .row.photos-context {
    margin-top: 10px;
  }
  .nl2br {
    white-space: pre-line;
  }
</style>
